/*      */
/* HOME */
/*      */
.home {
  justify-content: center;
}

.home h1 {
  font-size: 70px;
  color: #CCA352;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
}

#about, #gallery, #service, #contact {
  min-height: auto;
}

#about, #contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}

#about div, #contact div {
  display: flex;
  flex-direction: column;
}

/*        */
/* BANNER */
/*        */
.banner {
  background: linear-gradient(rgba(0, 0, 0, 0.712), rgba(255, 255, 255, 0.055)), url('./photos/assets/bannerimage.png') center fixed; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  height: 120vh;
}

.banner button {
  margin-top: 40px;
  padding: 12px;
  width: 200px;
  border-radius: 100px;
  background-color: #cca352;
  cursor: pointer;
  font-size: 20px;
  font-family: 'Zen Old Mincho', serif; 
  font-weight: bold; 
  border: none;
  text-transform: uppercase;
}

.banner button:hover {
  background-color: transparent;
  color: #DDA74F;
  border: 1px solid #CCA352;
  transition: all 500ms;
}

.banner h1 {
  color: white;
  font-size: 60px;
  text-transform: inherit;
}

/*       */
/* ABOUT */
/*       */
#about {
  margin-bottom: 80px;
}

#about img {
  width: 400px;
  justify-self: end;
  margin: 20px;
  margin-bottom: 0;
}

#about div {
  width: 50%;
}

#about h1 {
  position: relative;
  text-align: left;
  left: -100px;
}

#about p {
  width: 300px;
  line-height: 2rem;
}

/*         */
/* SERVICE */
/*         */
#service {
  min-height: 40vh;
  border: 1px solid saddlebrown;
  margin: 0px 20%;
  padding-bottom: 40px;
}

#service img {
  border-radius: 1000px;
  max-width: 250px;
}

#service a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  text-align: center;

}

#service button {
  padding: 10px;
  border: 1px solid black;
  background-color: white;
  text-align: center;
  cursor: pointer;
  width: 80%;
}

#service button:hover {
  border: 1px solid #CCA352;
  color: #CCA352;
  transition: all 0.3s ease;
}

/* barbers */
.barbers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.barbers div {
  padding: 10px;
  margin-bottom: 5%;
}

/* haircuts */
.haircuts {
  display: inherit;
}

.haircuts div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.haircuts .description h3 {
  margin-bottom: 0;
}

.haircuts div div:first-child {
  width: 150%;
  display: block;
  justify-content: left;
  margin-left: 40px;
}


.haircuts div div:nth-child(2) {
  font-size: 12px;
}

/*         */
/* GALLERY */
/*         */
.photos {
  margin: 0px 20%;
  line-height: 0;
  -moz-column-count:    4;
  -webkit-column-count: 4;
  column-count:         4;
  -webkit-column-gap:   0px;
  -moz-column-gap:      0px;
  column-gap:           0px; 
}

.photos img {
  width: 100% !important;
  height: auto !important;
}

.img-wrapper {
  border: 5px solid white;
}

/*         */
/* CONTACT */
/*         */
#contact {
  margin-top: 100px;
}

#contact form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

#contact img {
  width: 400px;
  justify-self: center;
}

#contact h1 {
  margin: 0;
  text-align: left;
}

#contact form input, textarea {
  margin-top: 2rem;
  border: none;
  border-bottom: 1px solid black;
  padding: 20px;
  padding-left: 5px;
  font-size: 16px;
}

#contact textarea {
  height: 100px;
  resize: none;
}

#contact #submit {
  margin-top: 2rem;
  padding: 10px;
  border: 1px solid black;
  background-color: white;
  text-align: center;
  cursor: pointer;
}

#contact #submit:hover {
  border: 1px solid #CCA352;
  color: #CCA352;
  transition: all 0.3s ease;
}

#contact #message {
  font-size: 18px;
  color: white;
  margin: 0;
  height: fit-content;
  padding-top: 10px;
}

/*                            */
/* TIMETABLE, PHONE AND ADRES */
/*                            */
#tpa {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin: 100px 0px;
}

/*     */
/* MAP */
/*     */
#map {
  display: flex;
  justify-content: center;
}

#map iframe {
  width: 100%;
  height: 500px;
  border: none;
}

/* ANIMATIONS */
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1.5s all ease;
}
.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
.reveal.left {
  opacity: 0;
  transition: 1s all ease;
  transform: translateX(-300px);
}
.reveal.left.active {
  transform: translateY(0);
  opacity: 1;
}
.reveal.right {
  opacity: 0;
  transition: 1s all ease;
  transform: translateX(300px);
}
.reveal.right.active {
  transform: translateY(0);
  opacity: 1;
}
.reveal.tpa {
  transform: translateY(0);
  transition: 1.5s all ease;
}
@keyframes wipe-enter {
	0% {
		transform: scale(0, 1);
	}
	50% {
		transform: scale(1, 1);
	}
}
@media (prefers-reduced-motion: no-preference) {
  .chevron {
    animation-name: wipe-enter;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
}


/*            */
/* RESPONSIVE */
/*            */
/* photos */
@media (max-width: 1500px) {
  .photos {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 800px) {
  .photos {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 400px) {
  .photos {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}
@media screen and (max-width: 1080px) {
  /* banner */
  .banner {
    background: linear-gradient(rgba(0, 0, 0, 0.712), rgba(255, 255, 255, 0.055)), url('./photos/assets/bannerimageSmart.png') fixed;
  }
}
/* responsive: ipad */
@media screen and (max-width: 920px){
  /* home */
  .home h1 {
    font-size: 55px;
  }

  /* about */
  #about h1 {
    width: 400px;
  }

  /* service */
  #service, .photos {
    margin: 0px 10%;
  }

  /* CSS FOR BUTTON */
  /* .barbers {
    margin-bottom: 40px;
  } */

  .haircuts div {
    display: block;
    text-align: center;
  }

  .haircuts div div:first-child {
    margin-left: 0px;
    width: 100%;
  }

  /* contact */
  #contact img, #about img {
    width: 320px;
  }
}

/* responsive: smartphone */
@media screen and (max-width: 720px){
  /* home */
  .home h1 {
    font-size: 40px;
    margin-bottom: 5px;
    width: 100%;
  }

  #about img, #contact img {
    width: 250px;
    justify-self: center;
  }

  #about h1, #contact h1 {
    position: initial;
    text-align: center;
    width: 100%;
  }

  #about, #contact {
    grid-template-columns: 1fr;
  }

  /* about */
  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #about div {
    width: 100%;
  }

  #about div:nth-child(2).reveal{
    transform: translate(0);
  }
  
  #about p {
    margin: 0;
    align-self: center;
    text-align: justify;
    width: 80%;
  }

  /* service */
  #service {
    margin: 10px;
  }

  .barbers img {
    width: 180px;
  }

  /* contact */
  #contact form {
    align-self: center;
  }
  #contact h1 {
    margin-top: 1rem;
  }

  /* tpa */
  #tpa {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 450px){
  /* banner */
  .banner {
    background: linear-gradient(rgba(0, 0, 0, 0.712), rgba(255, 255, 255, 0.055)), url('./photos/assets/bannerimageSmart+.png') fixed;
  }
}


@media screen and (max-width: 300px){

  /* home */
  .home h1 {
    font-size: 35px;
  }

  /* about */
  #about img {
    width: 80%; 
  }

  #about p {
    font-size: 14px;
  } 

  /* contact */
  #contact form input, #contact form textarea {
    margin-top: 0;
  }

  #contact form {
    width: 80%;
  }

  #contact #submit {
    margin-top: 20px;
  }

  /* service */
  .haircuts small {
    font-size: 10px;
  }
}

