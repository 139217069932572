body {
  font-family: 'Zen Old Mincho', serif;  font-weight: 400;
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

#loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}