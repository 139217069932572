/* NAVBAR */
.navbar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.15rem;
  z-index: 10;
  width: 100%;
  height: 200px;
  font-size: 20px;
}

/* NAV-MENU */
.nav-menu {
  display: flex;
}

/* LOGO */
.logo {
  justify-self: start;
  margin-left: 20px;
  z-index: 12;
}

.logo img {
  width: 180px;
  z-index: 10;
}

/* MENU ICON */
.menu-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  z-index: 10;
  color: #CCA352;
}

/* LINKS */
.links {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style-type: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
  width: 70vw;
}

.navbar a {
  text-decoration: none;
  color: #CCA352;
  overflow: hidden;
  display: block;
  position: relative;
  padding: 0.2em 0;
  margin-right: 10px;
}

.navbar a:hover{
  color: #A76B09;
  transition: color 500ms;
}

/* Fade in animation */
.links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 100%;
  height: 0.1em;
  background-color: #A76B09;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
.links a::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}
.links a:hover::after,
.links a:focus::after{
  transform: translate3d(0, 0, 0);
}

/* SOCIALMEDIA */
.socialmedia {
  display: flex;
  justify-content: end;
  list-style: none;
  font-size: 20px;
  margin-right: 10px;
}

.socialmedia li {
  margin-right: 10px;
}

/* RESPONSIVE */
@media screen and (max-width: 1500px){
  .nav-menu {
    width: 74%;
  }
}
@media screen and (max-width: 940px){
  .logo {
    margin: 0;
  }
  .logo img {
    width: 200px;
  }

  .logo.active {
    position: fixed;
  }

  .menu-icon {
    display: inherit;
    font-size: 30px;
  }

  .nav-menu {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -100%;
    opacity: 1;
    transition: all 500ms ease;
    padding: 0;
    padding-top: 95vh;
    gap: 40px;
    
  }

  .nav-menu li {
    width: 100%;
  }

  .nav-menu a {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .socialmedia, .links {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .links {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 35px;
  }

  .socialmedia {
    height: 10%;
    flex-direction: row;
    font-size: 35px;
  }

  .socialmedia li {
    margin: 0;
    width: 50%;
  }

  .links a::after {
    background-color: white;
  }

  .nav-menu.active {
    background-color: rgba(255, 255, 255, 1);
    left: 0;
    opacity: 1;
    transition: all 0.5 ease;
    z-index: 1;
  }

  .fa-times {
    top: 20px;
    right: 20px;
    position: fixed;
  }
}
@media screen and (max-width: 640px){
  .logo img {
    width: 150px;
  }

  .links {
    font-size: 20px;
  }

  .menu-icon {
    font-size: 25px;
  }

  .nav-menu {
    padding-top: 85vh;
  }

  .links {
    font-size: 20px;
  }

  .socialmedia {
    font-size: 20px;
  }
}

