/* FOOTER */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: #CCA352;
  /* background-color: #181619; */
  color: white;
}

.footer p {
  /* padding-left: 80px; */
  padding: 0 0 5px 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin: 0;
}

.footer svg {
  font-size: 30px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0);
}

.footer svg:hover {
  color: #CCA352;
  background-color: white;
  border: 1px solid rgb(255, 255, 255);
  transition: 500ms ease;
}

.footer a {
  position: absolute;
  right: 0;
}

/* RESPONSIVE */
@media screen and (max-width: 900px) {
  .footer p {
    padding-left: 0px;
  }
}
@media screen and (max-width: 640px) {
  .footer p {
    font-size: 10px;
    /* margin-left: 5px; */
  }

  .footer svg {
    font-size: 30px;
    margin-left: 10px;
  }
}



